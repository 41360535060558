import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { Env } from './app/common/global_services/server_url';
import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense('MzQxNjk3N0AzMjMxMmUzMTJlMzMzNWhRcFMxY091akZ6MVJXQ0laTzIwZVBCVmd3Q2FnUVlxemhCWTFLR2s2N2M9;MzQxNjk3OEAzMjMxMmUzMTJlMzMzNWhJLzlLYzB5aWhCYnQ2VlFDM0J0ellPM1NERVdxaVVWNzgxNHZrWFlmc0E9;Mgo+DSMBaFt+QHFqVkNrXVNbdV5dVGpAd0N3RGlcdlR1fUUmHVdTRHRbQlVjTH5XdkFiW3ddcHc=;Mgo+DSMBPh8sVXJ1S0d+X1RPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9nSXZTc0VnWXtfcnxRQ2k=;ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5bdkBjXX9acXZdQmVV;NRAiBiAaIQQuGjN/V0d+XU9Hc1RDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS3pTfkVmWXtedHVXTmZdVA==;MzQxNjk4M0AzMjMxMmUzMTJlMzMzNUJ4YzY4VXBVcWVhYlgrYi9MeTQrTTRpb2hpalBRY1Z6K0l6NFJXZGRQR2s9;MzQxNjk4NEAzMjMxMmUzMTJlMzMzNUhFZnBoU1d1Q1ozL0lGYkNyRFIxdVg1MUh4OHRZdjU0TWhSTXNjNmVsZDA9;Mgo+DSMBMAY9C3t2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5bdkBjXX9acXdVQGVb;MzQxNjk4NkAzMjMxMmUzMTJlMzMzNUFEdlphZ2hhSHJmYmRrRXhuLzF2UHRYRFhjTWNHaWRsREVtWEZndHd0RWc9;MzQxNjk4N0AzMjMxMmUzMTJlMzMzNUxEMkQ5Z2FyZ1V5bGZKdkxwbVlOZzd1Z284WnU3SHFFYVR5TDdDNk5OSWM9;MzQxNjk4OEAzMjMxMmUzMTJlMzMzNUJ4YzY4VXBVcWVhYlgrYi9MeTQrTTRpb2hpalBRY1Z6K0l6NFJXZGRQR2s9');

if (Env.Prod) {
  enableProdMode();
}
if (!Env.Prod) {
  if (window) {
    window.console.log = function () { };
  }
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
