<div id="navBar" class="nav_bar p-3">
  <div [class.flex-basis-20]="innerWidth > 400">
    <img *ngIf="orgDetails && orgDetails?.length" (load)="imgLoaded($event)" class="img-fluid"
      [src]="hostedAssets+orgDetails[0].OrgLogoName" alt="logo">
  </div>
  <div class="orgName_container">
    <div *ngIf="orgDetails && orgDetails?.length" class="orgName overflow-content" isEllipsisActive
      [tooltipText]="orgDetails[0].OrgName" [ngTooltipInput]="ngbToolTipReferenceOne" placement="bottom" ngbTooltip
      #ngbToolTipReferenceOne="ngbTooltip">
      {{orgDetails[0].OrgName}}
    </div>

    <div class="survey_name overflow-content" isEllipsisActive [tooltipText]="screeningName"
      [ngTooltipInput]="ngbToolTipReferenceTwo" placement="bottom" ngbTooltip #ngbToolTipReferenceTwo="ngbTooltip"
      *ngIf="screeningName">
      {{screeningName}}
    </div>
  </div>

  <div class="user_id" [class.py-2]="innerWidth < 400" [class.text-right]="innerWidth > 400"
    [class.flex-basis-20]="innerWidth > 400">
    <span *ngIf="uid">
      ID: {{uid}}
    </span>
    <div class="time" *ngIf="!isMobile">
      {{ time | date: 'MMM d, y, h:mm:ss a' }}
    </div>
  </div>
</div>