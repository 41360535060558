import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[autofocus]'
})
export class AutofocusDirective implements OnChanges {
@Input() focusOn;
  constructor(private host: ElementRef) {}

  ngOnChanges() {
    if ( typeof(this.focusOn) === 'boolean' && this.focusOn) {
        setTimeout(() => this.host.nativeElement.focus(), 0);
        return;
      }
  }

  ngAfterViewInit() {
    this.host.nativeElement.focus();
  }
}