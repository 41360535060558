import { BreakpointObserver } from '@angular/cdk/layout';
import { Location } from '@angular/common'
import { Component, OnInit, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppHostSettings, Env, ServerHost } from 'src/app/common/global_services/server_url';
import { SwService } from 'src/app/views/sw/services/sw.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
  providers: [Location]
})
export class AuthenticationComponent implements OnInit {

  isIpad: Observable<boolean>;
  isWelcomePage: boolean;
  screeningName: any;
  orgDetails: any;
  hostedAssets = ServerHost.HostedAssets;
  isMobile: boolean;
  bannercolor: string;
  showroundshape: boolean;
  year = new Date().getFullYear();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    public location: Location,
    private swService: SwService,
    public route: ActivatedRoute
  ) { 
    this.route.data.subscribe(d => {
      console.log(d);
      if (d.data && d.data?.length && d.data[0].responseCode === 1) {
        const data = JSON.parse(d.data[0].responseData);
        this.orgDetails = data;
      }
    })
  }

  ngOnInit() {
    this.isIpad = this.breakpointObserver.observe('(max-width: 800px)')
      .pipe(
        map(r => {
          return r.matches;
        })
      );
    this.breakpointObserver.observe('(max-width: 767px), (min-width: 320px)')
      .subscribe((d: any) => {
        if (d.breakpoints['(max-width: 767px)']) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
    if (this.router.url === '/sw/welcome') {
      this.isWelcomePage = true;
    } else {
      this.isWelcomePage = false;
    }

    this.router.events
      .subscribe(r => {
        if (this.location.path() === '/sw/welcome') {
          this.isWelcomePage = true;
        } else {
          this.isWelcomePage = false;
        }
      });

  }
  redirectosw()
  {
    this.router.navigateByUrl('/sw/welcome');
  }
  setOrgStyle() {
    if(sessionStorage.getItem("brandcolor"))
    {
      this.bannercolor=sessionStorage.getItem("brandcolor");
    }
    else{
      this.bannercolor='#3175bcfc';
      this.showroundshape=true;
    }
    let styles = {
      
       'background-image': 'linear-gradient(180deg,'+this.bannercolor+ ' 0%, '+this.bannercolor+ ' 100%)' 
         
    };
    return styles;
}

}
