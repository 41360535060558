import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppStateService } from './common/global_services/app.state.service';
import { AppHostSettings, Env, ServerHost } from './common/global_services/server_url';
import { AuthService } from './views/auth/services/auth.service';
import { SwService } from './views/sw/services/sw.service';

@Injectable({ providedIn: 'root' })
export class AppResolver  {

  constructor(private swService: SwService,
    public title: Title,
    public router: Router,
    public appStateService: AppStateService,
    public authService: AuthService) { }

  async resolve() {
    this.appStateService.getSessionStorageFromCookie();
    const isRvUser = sessionStorage.getItem('isRvUser') === 'true';
    let result;
    result = isRvUser ? await this.authService.GetRvOrgDetails({ subDomain: this.getSubDomain() }).toPromise() : await this.swService.GetOrgDetails({ subDomain: this.getSubDomain() }).toPromise();

    if (result && result.length && result[0].responseCode === 1 && result[0].responseData) {
      const data = JSON.parse(result[0].responseData);
      sessionStorage.setItem('CEE', data[0].CEE);
      sessionStorage.setItem('orgDetails', result[0].responseData);
      if (data.length && data[0].OrgName) {
        this.title.setTitle(data[0].OrgName);
      }
      sessionStorage.setItem('orgGuid', data[0].OrgGuid);
      sessionStorage.setItem('brandcolor', data[0].Brandcolor);
      if (isRvUser) {
        sessionStorage.setItem('gRoleId', data[0].gRoleId);
      }
      this.swService.orgDetailsEvent.next(data);
    } else {
      this.title.setTitle('GoodSpace: Page not found');
      this.router.navigateByUrl('/pageNotFound', { state: { hideBtn: true } });
    }
    return result;
  }

  getSubDomain() {
    const fullURL = window.location.href;
    const protocolAndSubDomainStr = fullURL.split('.')[0];

    let subDomain;
    if (AppHostSettings.ProdMode == Env.Local) {
      subDomain = ServerHost.SubDomainName ? ServerHost.SubDomainName : window.location.hostname;
    }
    else {
      subDomain = protocolAndSubDomainStr.split('//')[1];
    }
    return subDomain;
  }
}
