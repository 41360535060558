import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-sidebar-nav-dropdown',
    templateUrl: './sidebar-nav-dropdown.component.html'
})
export class AppSidebarNavDropdownComponent {
    @Input() link: any;

    public isBadge() {
        return this.link.badge ? true : false;
    }

    public isIcon() {
        return this.link.icon ? true : false;
    }

    constructor() { }
}
