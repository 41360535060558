import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputClearDirective } from '.';
import { AutofocusDirective } from './autofocus/auto-focus.directive';
import { IsEllipsisActiveDirective } from './ellipsis/is-ellipsis-active.directive';
import { TextTrimDirective } from './text-trim/text-trim.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TextUppercaseDirective } from './text-trim/text-uppercase.directive';
@NgModule({
  declarations: [
    InputClearDirective,
    AutofocusDirective,
    IsEllipsisActiveDirective,
    TextTrimDirective,
    TextUppercaseDirective
  ],
  imports: [
    CommonModule,
    NgbModule
  ],
  exports: [
    InputClearDirective,
    AutofocusDirective,
    IsEllipsisActiveDirective,
    TextTrimDirective,
    TextUppercaseDirective
  ]
})
export class DirectivesModule { }
