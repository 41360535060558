import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { AppResolver } from './app.resolver';
import { WildCardComponent } from './component/wild-card/wild-card.component';
import { MaincontainerComponent, AuthenticationComponent } from './container';
import { SwContainerComponent } from './container/sw-container';

const routes: Routes = [
  {
    path: 'sw',
    component: SwContainerComponent,
    resolve: { data: AppResolver },
    children: [
      {
        path: 'welcome',
        loadChildren: () => import('./views/welcome-page/welcome-page.module').then(m => m.WelcomePageModule)
      },
      {
        path: ':name',
        loadChildren: () => import('./views/sw/sw.module').then(m => m.SwModule)
      }
    ]
  },
  {
    path: 'auth',
    component: AuthenticationComponent,
    resolve: { data: AppResolver },
    children: [
      {
        path: 'login',
        loadChildren: () => import('./views/auth/login/login.module').then(m => m.LoginModule)
      },
      {
        path: 'forgot-password',
        loadChildren: () => import('./views/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
      },
      {
        path: 'register',
        loadChildren: () => import('./views/auth/registration/registration.module').then(m => m.RegistrationModule),
        data: {
          title: 'Register'
        },
      }
    ]
  },
  {
    path: '',
    redirectTo: '/sw/welcome',
    pathMatch: 'full',
  },
  {
    path: 'rv',
    component: MaincontainerComponent,
    resolve: { data: AppResolver },
    data: {
      title: 'Response Viewer'
    },
    children: [
      {
        path: '',
        redirectTo: '/rv/list-of-schools',
        pathMatch: 'full'
      },
      {
        path: 'list-of-schools',
        loadChildren: () => import('./views/rv/rv.module').then(m => m.RvModule),
        data: {
          title: 'List of Schools'
        },
      },
      {
        path: 'home',
        loadChildren: () => import('./views/screening-list/screening-list.module').then(m => m.ScreeningListModule),
        data: {
          title: 'Home'
        },
      },
      {
        path: 'screenings',
        loadChildren: () => import('./views/screenings/screenings.module').then(m => m.ScreeningsModule),
        data: {
          title: 'Surveys'
        },
      },
      {
        path: 'alert',
        loadChildren: () => import('./views/alert/alert.module').then(m => m.AlertModule),
        data: {
          title: 'Alerts'
        },
      },
      {
        path: 'search',
        loadChildren: () => import('./views/search/search.module').then(m => m.SearchModule),
        data: {
          title: 'Search'
        },
      },
      {
        path: 'user-review',
        loadChildren: () => import('./views/user-review/user-review.module').then(m => m.UserReviewModule),
        data: {
          title: 'Survey Review'
        },
      },
      {
        path: 'manage-users',
        loadChildren: () => import('./views/manage-users/manage-users.module').then(m => m.ManageUsersModule),
        data: {
          title: 'Manage users'
        },
      },
      {
        path: 'survey-templates',
        loadChildren: () => import('./views/survey-templates/survey-templates.module').then(m => m.SurveyTemplatesModule),
        data: {
          title: 'Survey Templates'
        },
      },
      {
        path: 'user-profile',
        loadChildren: () => import('./views/user-profile/user-profile.module').then(m => m.UserProfileModule),
        data: {
          title: 'Profile'
        }
      },
      {
        path: 'settings',
        loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule),
        data: {
          title: 'Settings'
        }
      },
      {
        path: 'reporting-dashboard',
        loadChildren: () => import('./views/reporting-dashboard/reporting-dashboard.module').then(m => m.ReportingDashboardModule),
        data: {
          title: 'Analytics Dashboard'
        }
      },
      {
        path: 'indicators-mapping',
        loadChildren: () => import('./views/indicators-mapping/indicators-mapping.module').then(m => m.IndicatorsMappingModule),
        data: {
          title: 'Map Indicators'
        }
      },
      {
        path: 'reporting-users',
        loadChildren: () => import('./views/reporting-users/reporting-users.module').then(m => m.ReportingUsersModule),
        data: {
          title: 'Analytics User'
        }
      }
    ]
  },
  //Wild Card Route
  { path: '**', pathMatch: 'full', component: WildCardComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'top', scrollOffset: [0, 0] })],
  exports: [RouterModule]
})
export class AppRoutingModule {
  public constructor(private router: Router) {
  }
}
