import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { AppHostSettings } from './server_url';

export const regexp = {
  Date: '^([0-2][0-9]|(3)[0-1])(\-)(((0)[0-9])|((1)[0-2]))(\-)\d{4}$',
  FirstName: "^(?=.{1,40}$)[a-zA-Z]+(?:[-'.  ][a-zA-Z.'.-]+)*(\s)*?$",
  FirstNameMsg: 'Enter only characters with minimum 1 and maximum 40 characters.',

  LastName: "^(?=.{0,40}$)[a-zA-Z]+(?:[-'.  ][a-zA-Z.'.-]+)*(\s)*?$",
  LastNameMsg: 'Enter only characters with minimum 1 and maximum 40 characters.',

  Names: '^[a-zA-Z][\\sa-zA-Z]{2,19}$',
  NamesMsg: 'Enter only characters with minimum 3 and maximum 20 characters.',

  OrgName: '^[a-zA-Z0-9\,\-\.\&\(\)\'][\\sa-zA-Z0-9\,\-\.\&\(\)\']{2,49}$',
  OrgNameMsg: 'Enter only characters with minimum 3 and maximum 50 characters.',

  Designation: '^[a-zA-Z][\\sa-zA-Z\-\(\)\_]{1,49}$',
  DesignationMsg: 'Enter only characters with minimum 2 and maximum 50 characters.',

  MobileNumPattern: AppHostSettings.ProdMode ? '^[5-9][0-9]{9,9}$' : '^[5-9][0-9]{9,9}$',
  MobilePatternMsg: 'Enter valid Mobile number.',
  ContactNumber: /^[\d\+]{9,13}$/,
  ContactNumberMsg: 'Enter a vaild contact number.',

  NZMobileNumPattern: /(^[0][2][0123579]{1})(\d{6,8}$)$/,

  Pincode: '^[0-9]{4,4}$',
  PincodeMsg: 'Enter valid Post Code.',

  StreetNames: '^(?![0-9]*$)[a-zA-Z0-9\,\-][\\sa-zA-Z0-9\,\-\/\_]{2,99}$',
  StreetNamesMsg: 'Enter only characters with minimum 3 and maximum 100 characters.',

  LocalityNames: '^(?![0-9]*$)[a-zA-Z0-9\,\-][\\sa-zA-Z0-9\,\-\/\_]{2,49}$',
  LocalityNamesMsg: 'Enter only characters with minimum 3 and maximum 50 characters.',

  DistrictNames: '^(?![0-9]*$)[a-zA-Z0-9\,\-][\\sa-zA-Z0-9\,\-\/\_]{2,49}$',
  DistrictNamesMsg: 'Enter only characters with minimum 3 and maximum 50 characters.',

  WebSite: /^(https?:\/\/)?([\da-zA-Z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
  WebSiteMsg: 'Enter valid website url.',

  // Email: /^(?=.{1,40}$)[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/,
  // tslint:disable-next-line:max-line-length
  Email: /^(?=.{1,79}$)(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  EmailMsg: 'Enter valid Email address.',

  savereason: /^[a-zA-Z0-9~!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\s]{3,500}$/,
  savereasonMsg: 'Enter only characters with minimum 3 and maximum 500 characters.',

  // small letter,capital letter,numbers and special characters with min 8 and max 12
  password: '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&#])[A-Za-z\d$@$!%*?&#].{9,30}',
  // tslint:disable-next-line:max-line-length
  //PasswordMsg: 'Password must contain Min 10 chars and at least 1 uppercase, 1 lowercase, 1 Number and 1 special character',
  PasswordMsg:'Password must match as per the password policy',

  VersionNo: /^[\d\+]{0,20}$/,
  VersionNoMsg: 'Enter a vaild version number.',
  // tslint:disable-next-line:max-line-length
  RegistersModuleAge: '^(0?[1-9]|[1-9][0-9]|[1][1-9][1-9]|200) (Years|years|Yrs|yrs|Months|months|Mnths|mnths|Weeks|weeks|Wks|wks|Days|days)$'
};

@Injectable({
  providedIn: 'root'
})
export class RegexpService {

  constructor() { }

  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }
}
