import { Injectable } from '@angular/core';
import { ServerHost } from 'src/app/common/global_services/server_url';
import { DatafactoryService } from 'src/app/common/global_services/datafactory.service';
import { RequestParams } from 'src/app/common/global_services/request-params';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

// OrgSetUp
export let SideBarNav = {
  getSidenavItems: ServerHost.WebApi + 'Home/GetNavigations',

};
@Injectable({
  providedIn: 'root'
})
export class SidebarNavService {

  private sideNavData = new BehaviorSubject('');
  currentData = this.sideNavData.asObservable();

  private sidenavId = new BehaviorSubject(0);
  currentsideNavId = this.sidenavId.asObservable();

  private orgData = new BehaviorSubject('');
  getCurrentOrgData = this.orgData.asObservable();


  constructor(
    public dataFactory: DatafactoryService,
    public _requestparams: RequestParams
  ) { }

  setSideNavId(id) {
    this.sidenavId.next(id);
  }

  GetSideNav(params?) {
    return this.dataFactory.postMethod(SideBarNav.getSidenavItems,
      this._requestparams.ConstructRequestForRv(params))
      .pipe(
        map(d => {
          if (!!d?.length && !d[0].message && d[0].responseData) {
            const sideNavData = JSON.parse(d[0].responseData);
            this.updateSideNav(sideNavData);
            return sideNavData;
          }
        })
      );

  }

  updateSideNav(item?: any) {
    this.sideNavData.next(item);
  }

  updateCurrentOrgData(data) {
    this.orgData.next(data);
  }

  getCurrentData() {
    return this.sideNavData;
  }

}
