<div id="fab-dismiss" *ngIf="fabTogglerState==='active'" (click)="onToggleFab()">
</div>

<div class="fab-container">
    <button mat-fab class="fab-toggler" class="fab-primary" color="primary" (click)="onToggleFab()">
        <i class="material-icons" [@fabToggler]="{value: fabTogglerState}">add</i> <!-- Animation here -->
    </button>
    <div [@speedDialStagger]="buttons.length">
        <!-- and here -->
        <button *ngFor="let btn of buttons" data-toggle="modal" [attr.data-target]="btn.modal" mat-mini-fab class="fab-secondary" color="secondary" (click)="trigger(btn)">
            <i *ngIf="btn.icon" class="material-icons">{{btn.icon}}</i>
            <img *ngIf="btn.iconURL" width="35" src="{{ASSETS.Icons + btn.iconURL}}" alt="">
        </button>
    </div>
</div>