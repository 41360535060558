import { environment } from "src/environments/environment";

export enum Env {
  Local,
  Dev,
  UAT,
  Prod

}
export const AppHostSettings = {
  ProdMode: Env.Dev, //Server Url
};

export let ServerHost: any;

if (AppHostSettings.ProdMode == Env.Prod || AppHostSettings.ProdMode == Env.Dev || AppHostSettings.ProdMode == Env.UAT) {

  ServerHost = {
    WebApi: environment.WebApi,
    HostedAssets: environment.HostedAssets,
    DomainName: environment.DomainName

  };
} else {
  ServerHost = {
    WebApi: 'https://gssdevapi-May2021.web20.cloud/api/',
    HostedAssets: 'https://assets.web20.cloud/',
    DomainName: 'web20.cloud',
    SubDomainName: 'app' // change this to u r required subdomain if not make it null. So that it will take by default as 'localhost'.
  };

}

// else if (AppHostSettings.ProdMode == Env.Dev) {
//   ServerHost = {
//     WebApi: environment.WebApi,
//     HostedAssets: environment.HostedAssets,
//     DomainName: environment.DomainName
//   };
// }
// else if (AppHostSettings.ProdMode == Env.UAT) {
//   ServerHost = {
//     WebApi: 'https://gssdevapi-jan2021.web20hosting.com/api/',
//     HostedAssets: 'https://assets.web20.cloud/',
//     DomainName: 'web20.cloud'
//   };
// }


