import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppStateService } from 'src/app/common/global_services/app.state.service';

import { Assets } from 'src/app/common/global_services/assets_path';
import { ServerHost } from 'src/app/common/global_services/server_url';
import { AuthService } from 'src/app/views/auth/services/auth.service';
import { SwService } from 'src/app/views/sw/services/sw.service';
import { SidebarNavService } from '../sidebar-nav/services/sidebar-nav.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

  ASSETS = Assets;
  orgDetails: any;
  hostedAssets = ServerHost.HostedAssets;
  loggedInUserDetails: Observable<any>;
  isShowBackIcon = false
  orgDetailsSub: Subscription;
  currentUrl: string;

  constructor(
    private router: Router,
    public swService: SwService,
    private sideNavService: SidebarNavService,
    private authService: AuthService,
    private appStateService: AppStateService,
    private title: Title
  ) {
    this.orgDetailsSub = this.swService.orgDetailsEvent$.subscribe(data => {
      this.orgDetails = data;
    });
  }

  ngOnInit() {
    this.isShowBackIcon = window.location.href.indexOf('/rv/list-of-schools') === -1 && sessionStorage.getItem('gRoleId') === '4' && sessionStorage.getItem('isShowBackButton') === 'true';
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.currentUrl = event.url;
        this.isShowBackIcon = event.url !== '/rv/list-of-schools' && sessionStorage.getItem('gRoleId') === '4' && sessionStorage.getItem('isShowBackButton') === 'true';
      });


    if (!this.orgDetails && !this.orgDetails?.length && sessionStorage.getItem('orgDetails')) {
      this.orgDetails = JSON.parse(sessionStorage.getItem('orgDetails'));
    }
    this.authService.isRefresh.subscribe(isRefresh => {
      if (isRefresh) {
        this.loggedInUserDetails = this.authService.GetLoggedInUserProfile();
      }
    });
    this.loggedInUserDetails = this.authService.GetLoggedInUserProfile();
  }

  logout() {
    const subDomain = sessionStorage.getItem('loggedinWithSubdomain');
    const domainUrl = sessionStorage.getItem('parentdomain');
    this.swService.clearSessionStorageExcept(['orgGuid', 'orgDetails', 'brandcolor']);
    window.location.replace(`https://${subDomain}.${domainUrl}/#/auth/login`);
  }

  handleBrandClick() {
    if (sessionStorage.getItem('GSTK') && sessionStorage.getItem('orgGuid')) {
      // let's consider first element of side nav array from Backend is the default navigation and that's where should navigate to when clicked on brand icon.
      const defaultNav = this.sideNavService.getCurrentData().value as any;
      if (defaultNav?.length && defaultNav[0].NavigationId) {
        this.sideNavService.setSideNavId(defaultNav[0].NavigationId);
        this.router.navigateByUrl( defaultNav[0].url);
        return;
      }
      // else case is the below
      this.sideNavService.setSideNavId(1);
      this.router.navigateByUrl( this.currentUrl.indexOf('/rv/list-of-schools') >= 0 ? '/rv/list-of-schools' : '/rv/home');
    } else {
      this.router.navigateByUrl('/auth/login');
    }
  }

  backToSchoolList(): void {
    const subDomain = sessionStorage.getItem('loggedinWithSubdomain');
    const domainUrl = sessionStorage.getItem('parentdomain');
    sessionStorage.setItem('orgGuid', sessionStorage.getItem('appDomainOrgGuid'));
    const isAppDomainRouting = sessionStorage.getItem('isAppDomainRouting');
    sessionStorage.setItem('isShowBackButton', 'false');
    if (isAppDomainRouting === 'true') {
      this.router.navigate(['/rv/list-of-schools']);
    } else {
      this.appStateService.navigateToSchools('/rv/list-of-schools', subDomain, domainUrl);
    }
  }

  ngOnDestroy() { }

}
