import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Assets } from 'src/app/common/global_services/assets_path';
import { speedDialFabAnimations } from './speed-dial-fab.animations';

@Component({
    selector: 'app-speed-dial-fab',
    templateUrl: './speed-dial-fab.component.html',
    styleUrls: ['./speed-dial-fab.component.scss'],
    animations: speedDialFabAnimations
})
export class SpeedDialFabComponent implements OnChanges, OnDestroy {
@Input() fabBtns;
@Output() triggerBtn = new EventEmitter<any>();

    buttons = [];
    fabTogglerState = 'inactive';
    ASSETS = Assets;
    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        const btns = changes.fabBtns;
        if (btns) {
            this.fabBtns = btns.currentValue;
        }
    }

    ngOnDestroy() {
    }

    showItems() {
        this.fabTogglerState = 'active';
        this.buttons = this.fabBtns;
    }

    hideItems() {
        this.fabTogglerState = 'inactive';
        this.buttons = [];
    }

    onToggleFab() {
        this.buttons.length ? this.hideItems() : this.showItems();
    }
    trigger(btn) {
        this.triggerBtn.emit(btn);
    }
}
