import { isDevMode } from '@angular/core';

const pre = isDevMode ? `${window.location.protocol}//${window.location.host}/` : '';

export let Assets = {
    Images: pre + 'assets/image',
    Icons: pre + 'assets/image/icons',
    Avatars: pre + 'assets/image/avatars'
};


