import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-screening-footer',
  templateUrl: './screening-footer.component.html',
  styleUrls: ['./screening-footer.component.scss']
})
export class ScreeningFooterComponent implements OnInit {
  brandbtnCss = {
    'background': sessionStorage.getItem("brandcolor")
    
  };
  year = new Date().getFullYear();
  constructor() { }

  ngOnInit(): void {
  }

}
