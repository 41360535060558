import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnChanges } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Directive({
    selector: '[isEllipsisActive]'
})
export class IsEllipsisActiveDirective implements OnChanges, AfterViewInit {

    @Input() tooltipText: string;
    @Input() ngTooltipInput: NgbTooltip;
    @HostListener('mouseover')
    onMouseOver() {
        const element = this._elementRef.nativeElement;
        if (element.offsetWidth < element.scrollWidth) {
            if (this.ngTooltipInput) {
                this.ngTooltipInput.ngbTooltip = this.tooltipText;
            }
        }
    }

    constructor(private _elementRef: ElementRef) { }

    ngOnChanges(): void {
        const element = this._elementRef.nativeElement;
        if (element.offsetWidth < element.scrollWidth) {
            if (this.ngTooltipInput) {
                this.ngTooltipInput.ngbTooltip = this.tooltipText;
            }
        }
        this.ngTooltipInput.triggers
    }

    ngAfterViewInit(): void {
        const element = this._elementRef.nativeElement;
        if (element.offsetWidth < element.scrollWidth) {
            if (this.ngTooltipInput) {
                this.ngTooltipInput.ngbTooltip = this.tooltipText;
            }
        }
    }
}
