import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Alert } from 'src/app/common/global_services/alert.service';
import { DatafactoryService } from 'src/app/common/global_services/datafactory.service';
import { RequestParams } from 'src/app/common/global_services/request-params';
import { ServerHost } from 'src/app/common/global_services/server_url';
import { AppSecurityAPis } from '../../settings/services/settings.service';

const AuthAPIs = {
  Login: ServerHost.WebApi + 'Accounts/Login',
  Authenticate: ServerHost.WebApi + 'Accounts/Authenticate',
  ConfirmEmail: ServerHost.WebApi + 'Accounts/ConfirmEmail',
  RegisterUser: ServerHost.WebApi + 'Accounts/RegisterUser',
  GetLoggedInUserProfile: ServerHost.WebApi + 'Home/GetLoggedInUserProfile',
  RegisterInvitedUser: ServerHost.WebApi + 'Accounts/RegisterInvitedUser',
  ForgotPasswordForEmailOTP: ServerHost.WebApi + 'Accounts/ForgotPasswordForEmailOTP',
  VerifyOTPwdForEmailOTP: ServerHost.WebApi + 'Accounts/VerifyOTPwdForEmailOTP',
  ResetPasswordForEmailOTP: ServerHost.WebApi + 'Accounts/ResetPasswordForEmailOTP',
  CheckInviteUserGuid: ServerHost.WebApi + 'Accounts/CheckInviteUserGuid',
  GetRvOrgDetails: ServerHost.WebApi + 'PortalHome/GetRvOrgDetails'
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public refreshUserDetails = new BehaviorSubject(false);
  isRefresh = this.refreshUserDetails.asObservable();

  constructor(
    private dataFactory: DatafactoryService,
    private requestParam: RequestParams,
    private http: HttpClient,
    private alertService: Alert
  ) { }

  ResetPasswordForEmailOTP(param: { Email: string, NewPassword: string }) {
    return this.dataFactory.postAnonymousMethod(AuthAPIs.ResetPasswordForEmailOTP, param);
  }

  handleForgotPassword(param: { Email: string, subDomain: string }) {
    return this.dataFactory.postAnonymousMethod(AuthAPIs.ForgotPasswordForEmailOTP, param);
  }

  VerifyOTPwdForEmailOTP(param: { Email: string, EnteredOTP: string, IsEmailChangeto: number }) {
    return this.dataFactory.postAnonymousMethod(AuthAPIs.VerifyOTPwdForEmailOTP, param);
  }

  GetLoggedInUserProfile(param?) {
    return this.dataFactory.postMethod(AuthAPIs.GetLoggedInUserProfile,
      this.requestParam.ConstructRequestForRv(param))
      .pipe(
        map(data => {
          if (!!data?.length && data[0].response === 'success') {
            const parsedData = JSON.parse(data[0].responseData);
            sessionStorage.setItem("ECP", parsedData[0].ExcelPassword);
            return parsedData[0];
          }
        }),
      );
  }

  authenticate(params) {
    return this.dataFactory.postAnonymousMethod(AuthAPIs.Authenticate, params);
  }

  login(params) {
    return this.dataFactory.postAnonymousMethod(AuthAPIs.Login, params);
  }
  
  verifyLoginOtp(params: {Email: string, EnteredOTP: string, IsEmailChange: boolean, IsEmailCancel: boolean}, tempToken) {
    const headers = new HttpHeaders().set('Accept', 'application/json')
        .set('Content-Type', 'application/json;charset=utf-8').set('Access-Control-Allow-Origin', '*')
        .set('Authorization', 'Bearer ' + tempToken);
    return this.dataFactory.postMethodWithCustomToken(AppSecurityAPis.ValidateOTPfor2FA, this.requestParam.ConstructRequest({...params}), tempToken);
  }

  ConfirmEmail(params) {
    return this.dataFactory.postMethod(AuthAPIs.ConfirmEmail, this.requestParam.ConstructRequest(params));
  }

  RegisterUser(params) {
    return this.dataFactory.postMethod(AuthAPIs.RegisterUser, this.requestParam.ConstructRequest(params));
  }

  RegisterInvitedUser(params: { orgUserInviteGuid: string, subDomain: string, email: string, password: string, userData: string, iaeu?: string }) {
    return this.dataFactory.postMethod(AuthAPIs.RegisterInvitedUser, this.requestParam.ConstructRequest(params));
  }

  CheckInviteUserGuid(params: { InviteUserGuid: string, inviteUserGuid: string }) {
    return this.dataFactory.postMethod(AuthAPIs.CheckInviteUserGuid, this.requestParam.ConstructRequest(params));
  }

  GetRvOrgDetails(params?) {
    return this.dataFactory.postMethod(AuthAPIs.GetRvOrgDetails, this.requestParam.ConstructRequest(params));
  }

}
