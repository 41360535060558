<header class="app-header navbar">
  <div class="row w-100 ml-0">
    <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 pl-0 brand-logo">
      <a *ngIf="orgDetails && orgDetails?.length" class="navbar-brand" (click)="handleBrandClick()"
        [ngStyle]="{'backgroundImage': 'url('+ hostedAssets+orgDetails[0].OrgLogoName + ')'}">
        <!-- <img *ngIf="orgDetails && orgDetails?.length" class="img-fluid" [src]="hostedAssets+orgDetails[0].OrgLogoName" alt="logo"> -->
      </a>

      <button class="navbar-toggler d-lg-none" type="button" appMobileSidebarToggler>
        <span class="navbar-toggler-icon"></span>
      </button>

      <button class="navbar-toggler d-md-down-none" type="button" appSidebarToggler>
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
    <h3 *ngIf="orgDetails && orgDetails?.length"
      class="col-sm-4 col-md-4 col-lg-4 col-xl-4 text-white m-auto text-center org-name" isEllipsisActive
      [tooltipText]="orgDetails[0]?.OrgName"
      [ngTooltipInput]="ngbToolTipReference" placement="bottom" ngbTooltip #ngbToolTipReference="ngbTooltip">
      {{orgDetails[0]?.OrgName}}
    </h3>


    <ul class="nav navbar-nav col-sm-4 col-md-4 col-lg-4 col-xl-4 user-info pr-0">
      <li class="nav-item cursor_pointer" *ngIf="isShowBackIcon">
        <i class="fa fa-arrow-left text-white back-icon" title="Back to schools" (click)="backToSchoolList()"></i>
      </li>
      <li class="nav-item cursor_pointer">
        <a class="nav-link" data-toggle="modal" role="button" routerLink="/rv/user-profile">
          <i class="fa fa-2x fa-user text-white" title="Log out"></i>
          <ng-container *ngIf="(loggedInUserDetails|async) as userDetails">
            <span class="text-white mx-1">
              {{userDetails.Title}}<ng-container *ngIf="userDetails.Title">&nbsp;</ng-container>
              {{userDetails.FirstName}}
            </span>
          </ng-container>
        </a>
      </li>
      <li class="nav-item cursor_pointer">
        <a class="nav-link" data-toggle="modal" role="button" (click)="logout()">
          <i class="fa fa-2x fa-sign-out text-white" title="Log out"></i>
        </a>
      </li>
    </ul>
  </div>
</header>