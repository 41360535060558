import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
// import { ErrorHandler } from '@angular/core';
import { RaygunErrorHandler } from './app.raygun.setup';
import { ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  MaincontainerComponent,
  AuthenticationComponent,
  SwContainerComponent
} from './container';

const app_container = [
  AuthenticationComponent,
  MaincontainerComponent,
  SwContainerComponent
];

import {
  AsideComponent,
  BreadcrumbsComponent,
  FooterComponent,
  NavbarComponent,
  SidebarComponent,
  SidebarMinimizerComponent,
  AppSidebarNavComponent,
  LoaderComponent,
  AppSidebarNavItemComponent,
  AppSidebarNavLinkComponent,
  AppSidebarNavTitleComponent,
  AppSidebarNavDropdownComponent
} from './component';

const APP_SIDEBAR_NAV = [
  AppSidebarNavComponent,
  AppSidebarNavDropdownComponent,
  AppSidebarNavItemComponent,
  AppSidebarNavLinkComponent,
  AppSidebarNavTitleComponent
];

const app_components = [
  AsideComponent,
  BreadcrumbsComponent,
  FooterComponent,
  NavbarComponent,
  SidebarComponent,
  SidebarMinimizerComponent,
  AppSidebarNavComponent,
  APP_SIDEBAR_NAV,
  LoaderComponent
];

// Import directives
import {
  AsideToggleDirective,
  NAV_DROPDOWN_DIRECTIVES,
  ReplaceDirective,
  SIDEBAR_TOGGLE_DIRECTIVES
} from './directives';
import { DatafactoryService } from './common/global_services/datafactory.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { RegexpService } from './common/global_services/regexp.service';
import { RequestParams } from './common/global_services/request-params';
import { SidebarNavService } from './component/sidebar-nav/services/sidebar-nav.service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { DatePipe } from '@angular/common';
import { SpeedDialFabModule } from './component/SpeedDialFab/speed-dial-fab.module';
import { SwLayoutModule } from './component/sw-layout.module';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DirectivesModule } from './directives/directives.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const APP_DIRECTIVES = [
  AsideToggleDirective,
  NAV_DROPDOWN_DIRECTIVES,
  ReplaceDirective,
  SIDEBAR_TOGGLE_DIRECTIVES
];

@NgModule({
  declarations: [
    AppComponent,
    ...app_components,
    ...app_container,
    ...APP_DIRECTIVES
  ],
  imports: [
    SwLayoutModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    NgIdleKeepaliveModule.forRoot(),
    HammerModule,
    SpeedDialFabModule,
    DatePickerModule,
    DirectivesModule,
    NgbModule
  ],
  providers: [RequestParams, RegexpService,
    SidebarNavService, DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DatafactoryService,
      multi: true,
    },
   { provide: ErrorHandler, useClass: RaygunErrorHandler }
  ],

  // providers: [DatafactoryService,RegexpService],
  bootstrap: [AppComponent]
})
export class AppModule { }
