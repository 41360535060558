import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-wild-card',
  templateUrl: './wild-card.component.html',
  styleUrls: ['./wild-card.component.scss']
})
export class WildCardComponent implements OnInit {
  hideBtn: any;

  constructor(public router: Router) {
    this.hideBtn = history.state.hideBtn;
   }

  ngOnInit(): void {
  }

  navigate() {
    this.router.navigateByUrl('/sw/welcome');
  }

}
