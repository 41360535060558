// mustBeSingle => if true and is option selected then shouldn't consider or remmove remaining 
export class QuestionBase<T> {
    value: T;
    key: string;
    label: string;
    required: boolean;
    order: number;
    controlType: string;
    type: string;
    options: {OptionId:any, OptionName: string, OptionValue: string, IsSelected: boolean, mustBeSingle?: boolean, IsDisabled?: boolean, OptionRegEx?: string}[];
    disable: boolean;
    isHavingProbingQuestion: boolean;
    questionId: any;
    probingQuestion: QuestionBase<T>[];
    isCompleted: boolean;
    isAnswered: boolean;
    isSkipped: boolean;
    isProbing: boolean;
    validationMessage: string;
    validators: object;
    fieldErrorLabel: string;
    prefixComponent: QuestionBase<T>;

    constructor(options: {
        value?: T;
        key?: string;
        label?: string;
        required?: boolean;
        order?: number;
        controlType?: string;
        type?: string;
        options?: {OptionId: any, OptionName: string, OptionValue: string, IsSelected: boolean, mustBeSingle?: boolean, IsDisabled?: boolean, OptionRegEx?: string}[];
        disable?: boolean;
        isHavingProbingQuestion?: boolean;
        questionId?: any;
        probingQuestion?: QuestionBase<T>[];
        isAnswered?: boolean;
        isSkipped?: boolean;
        isProbing?: boolean;
        validationMessage?: string;
        validators?: object;
        fieldErrorLabel?: string;
        prefixComponent?: QuestionBase<T>;
      } = {}) {
      this.value = options.value;
      this.key = options.key || '';
      this.label = options.label || '';
      this.required = !!options.required;
      this.order = options.order === undefined ? 1 : options.order;
      this.controlType = options.controlType || '';
      this.type = options.type || '';
      this.options = options.options || [];
      this.disable = options.disable;
      this.isHavingProbingQuestion = options.isHavingProbingQuestion;
      this.questionId = options.questionId;
      this.probingQuestion = options.probingQuestion;
      this.isAnswered = !!options.isAnswered;
      this.isSkipped = !!options.isSkipped;
      this.isProbing = !!options.isProbing;
      this.validationMessage = options.validationMessage;
      this.validators = options.validators;
      this.fieldErrorLabel = options.fieldErrorLabel;
      this.prefixComponent = options.prefixComponent;
    }
  }
  