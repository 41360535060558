import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-sidebar-nav-link',
    templateUrl: './sidebar-nav-link.component.html'
})
export class AppSidebarNavLinkComponent {
    @Input() link: any;

    public hasVariant() {
        return this.link.variant ? true : false;
    }

    public isBadge() {
        return this.link.badge ? true : false;
    }

    public isExternalLink() {
        return this.link?.url?.substring(0, 4) === 'http' ? true : false;
    }

    public isIcon() {
        return this.link.icon ? true : false;
    }

    public hideMobile() {
        if (document.body.classList.contains('sidebar-mobile-show')) {
            document.body.classList.toggle('sidebar-mobile-show');
        }
    }

    constructor() { }
}
