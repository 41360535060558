import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarMinimizerService {

  private state = new BehaviorSubject(true);
  sidebarState = this.state.asObservable();

  constructor() { }

  setSidebarState(value: boolean) {
    this.state.next(value);
  }
}
