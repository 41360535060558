import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appInputClear]'
})
export class InputClearDirective implements OnInit {

  @Input() keyName;

  constructor(public el: ElementRef, public renderer: Renderer2) {
    this.el.nativeElement.addEventListener('keydown', (ev) => {
      if (this.el.nativeElement.value.length !== 0) {
        this.showIcon();
      }
    })
  }

  ngOnInit() { }

  @HostListener('mouseenter') onMouseEnter() {
    this.showIcon();
  }

  @HostListener('blur') onMouseLeave() {
    this.hideIcon();
  }

  private showIcon() {
    if (!document.getElementById(this.keyName ? this.keyName : 'clearBtn') && this.el.nativeElement.value.length !== 0) {
      const iconEL = this.createIconEl();
      this.el.nativeElement.parentNode.setAttribute('class', 'position-relative');
      this.renderer.insertBefore(this.el.nativeElement.parentNode, iconEL, this.el.nativeElement);
      this.renderer.listen(iconEL, 'click', (event) => { 
          this.el.nativeElement.value = '';
          this.hideIcon();
      }).bind(this);
    }
    const icon = document.getElementById(this.keyName ? this.keyName : 'clearBtn');
    if (icon) {
      if (this.el.nativeElement.value.length) {
        icon.style.display = "block";
      } else {
        this.hideIcon();
      }
    }
    
  }

  private createIconEl() {
    const iconEL = document.createElement('i');
    iconEL.setAttribute('class', 'fa fa-2x fa-times position-absolute cursor_pointer');
    iconEL.setAttribute('id', this.keyName ? this.keyName.toString() : 'clearBtn');
    iconEL.style.right = '2%';
    iconEL.style.bottom = '18%';
    iconEL.style.display = 'block';
    return iconEL;
  }

  private hideIcon() {
    const clearBtn = document.getElementById(this.keyName ? this.keyName : 'clearBtn');
    console.log('hiding', this.keyName);
    if (clearBtn) {
      clearBtn.style.display = "none";
    }
  }

}
