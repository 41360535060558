import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { DatafactoryService } from "src/app/common/global_services/datafactory.service";
import { RequestParams } from "src/app/common/global_services/request-params";
import { ServerHost } from "src/app/common/global_services/server_url";

export let AppSecurityAPis = {
  SaveOrgSecuritySettings:
    ServerHost.WebApi + "AppSecurity/SaveOrgSecuritySettings",
  GetOrgWhitelistedIPs: ServerHost.WebApi + "AppSecurity/GetOrgWhitelistedIPs",
  ValidateLogedInUserIP:
    ServerHost.WebApi + "AppSecurity/ValidateLogedInUserIP",
  GenerateOTPFor2FA: ServerHost.WebApi + "AppSecurity/GenerateOTPFor2FA",
  ValidateOTPfor2FA: ServerHost.WebApi + "AppSecurity/ValidateOTPfor2FA",
  GetLookupData: ServerHost.WebApi + 'Home/GetLookupData'
};

@Injectable({
  providedIn: "root",
})

export class SettingsService {
  constructor(
    public dataFactory: DatafactoryService,
    public _requestparams: RequestParams
  ) {}

  SaveOrgSecuritySettings(params: {RestrictBasedOnIP: boolean, AllowedPublicIPAddressJSON: string, FAForOrgNetworkid: number, FAForOutsideNetworkid: number}) {
    return this.dataFactory.postMethod(AppSecurityAPis.SaveOrgSecuritySettings, this._requestparams.ConstructRequestForRv(params));
  }

  GetOrgWhitelistedIPs() {
    return this.dataFactory.postMethod(AppSecurityAPis.GetOrgWhitelistedIPs, this._requestparams.ConstructRequestForRv({}));
  }



  GetLookupData(param: {LookupTypeId: number}) {
    return this.dataFactory.postMethod(AppSecurityAPis.GetLookupData,
      this._requestparams.ConstructRequestForRv(param))
      .pipe(
        map(data => {
          // if (data[0].response === 'success') {
            return data;
          // }
        }),
      );
  }

}
