import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScreeningFooterComponent } from './screening-footer/screening-footer.component';
import { ScreeningNavbarComponent } from './screening-navbar/screening-navbar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DirectivesModule } from '../directives/directives.module';


@NgModule({
  declarations: [
    ScreeningNavbarComponent,
    ScreeningFooterComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    DirectivesModule
  ],
  exports: [
    ScreeningNavbarComponent,
    ScreeningFooterComponent,
  ],
})
export class SwLayoutModule { }
