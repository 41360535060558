import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { SidebarMinimizerService } from './sidebar-minimizer.service';

@Component({
  selector: 'app-sidebar-minimizer',
  templateUrl: './sidebar-minimizer.component.html'
})
export class SidebarMinimizerComponent {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private sidebarService: SidebarMinimizerService
  ) { }
  minimizer() {
    console.log(this.document.body.classList.contains('sidebar-minimized'));
    this.sidebarService.setSidebarState(this.document.body.classList.contains('sidebar-minimized'));
  }
}
