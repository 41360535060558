import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Router } from '@angular/router';
import { DataService } from './data.service';
import { Alert } from 'src/app/common/global_services/alert.service';
import { ServerHost } from 'src/app/common/global_services/server_url';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dashboard',
  templateUrl: './maincontainer.component.html',
  providers: [DataService, NgbModalConfig, NgbModal]
})

export class MaincontainerComponent implements OnInit, AfterViewInit {

  openModalBtnRef: ElementRef<HTMLButtonElement>;
  @ViewChild('openModalBtnRef', { read: ElementRef }) set setButtonRef(buttonRef) {
    if (buttonRef) {
      this.openModalBtnRef = buttonRef;
    }
  }

  constructor(
    private idle: Idle,
    private alertService: Alert,
    private data: DataService,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    private router: Router
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
    config.centered = true;
    config.size = 'lg';
  }
  ngAfterViewInit() {
  }
  ngOnInit() {
   
    // this.idle.setTimeout(1500);
    // this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    // this.idle.onIdleEnd.subscribe(() => console.log('No longer idle'));
    // this.idle.onTimeout.subscribe(() => {
    //   // this.foreceLogOut();
    // });

    // this.idle.onIdleStart.subscribe(() => {
    //   this.logout();
    // });
    // this.reset();
  }

  reset() {
    this.idle.watch();
  }

  logout() {

  }
}

