<app-navbar></app-navbar>
<div class="app-body">
  <app-sidebar></app-sidebar>
  <main class="main">
    <ol class="breadcrumb">
      <app-breadcrumbs></app-breadcrumbs>
      <!-- Breadcrumb Menu-->
      <li class="breadcrumb-menu d-md-down-none">
        <!-- <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
          <a class="btn" [routerLink]="['/dashboard']"><i class="fa fa-home"></i> &nbsp;Dashboard</a>
          <a class="btn" href="#"><i class="fa fa-cogs"></i> &nbsp;Settings</a>
        </div> -->
      </li>
    </ol>
    <div class="container-fluid">
      <app-loader></app-loader>
      <router-outlet></router-outlet>
    </div>
  </main>
  <app-aside></app-aside>
</div>
<app-footer></app-footer>
