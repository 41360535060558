import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[textUppercase]'
})
export class TextUppercaseDirective {
    constructor(private _control: NgControl) { }

    @HostListener('keyup', ['$event'])
    onInputChange(event) {
        const value: string = event.target.value;
        event.target.value = value.toUpperCase();
        this._control.control.setValue(value.toUpperCase());
    }
}
