import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { SidebarNavService } from './services/sidebar-nav.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-sidebar-nav',
  templateUrl: './sidebar-nav.component.html',
  styleUrls: ['./nav.scss']
})
export class AppSidebarNavComponent implements OnInit {
  public Version: string = environment.version;
  classToApply = 1;
  public navigation: any = [
    // {
    //   NavigationId: 1,
    //   icon: 'fa fa-home',
    //   name: 'Home',
    //   url: '/rv/home'
    // },
    // {
    //   NavigationId: 3,
    //   icon: 'fa fa-bell-o',
    //   name: 'Alerts',
    //   url: '/rv/alert',
    // },
    // {
    //   NavigationId: 4,
    //   icon: 'fa fa-search',
    //   name: 'Search',
    //   url: '/rv/search',
    // }
  ];


  constructor(
    private sideNavService: SidebarNavService
  ) { }

  ngOnInit() {
    this.sideNavService.currentsideNavId.subscribe(res => {
      this.classToApply = res;
    });

    this.sideNavService.currentData.subscribe(data => {
      this.navigation = data;
    })

    this.sideNavService.GetSideNav()
      .subscribe(res => {
        this.navigation = res;
      });

  }


  public isDivider(item) {
    return item.divider ? true : false;
  }

  public isTitle(item) {
    return item.title ? true : false;
  }

  onClick(id) {
    if (id) {
      this.classToApply = id;
    }
  }
}

@Component({
  selector: 'app-sidebar-nav-title',
  template: ''
})
export class AppSidebarNavTitleComponent implements OnInit {
  @Input() title: any;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    const nativeElement: HTMLElement = this.el.nativeElement;
    const li = this.renderer.createElement('li');
    const name = this.renderer.createText(this.title.name);

    this.renderer.addClass(li, 'nav-title');

    if (this.title.class) {
      const classes = this.title.class;
      this.renderer.addClass(li, classes);
    }

    if (this.title.wrapper) {
      const wrapper = this.renderer.createElement(this.title.wrapper.element);

      this.renderer.appendChild(wrapper, name);
      this.renderer.appendChild(li, wrapper);
    } else {
      this.renderer.appendChild(li, name);
    }
    this.renderer.appendChild(nativeElement, li);
  }
}

