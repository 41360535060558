<ng-container *ngIf="isMobile">
  <div class="app-header navbar">
    <h3 *ngIf="orgDetails && orgDetails?.length" class="text-white m-auto">
      {{orgDetails[0]?.OrgName}}
    </h3>
  </div>
</ng-container>


<div class="auth_container">
  <div class="main_container">
    <div *ngIf="!isMobile" class="org_banner">
      <div class="left_bottom_shape">
      </div>
      <div class="banner_body">
        <div *ngIf="orgDetails"
          class="rounded-circle position-relative overflow-auto m-auto bg-container d-flex justify-content-center align-items-center">
          <img (click)="redirectosw()" *ngIf="orgDetails && orgDetails?.length" class="position-absolute org_logo img-fluid"
            [src]="hostedAssets+orgDetails[0].OrgLogoName" alt="">
        </div>
        <h2 class="my-2" *ngIf="orgDetails && orgDetails?.length">{{orgDetails[0]?.OrgName}}</h2>
        <!-- <h4 class="off_white mt-1">
        {{questionnaireMetadata[0]?.QuestionnaireName}}
      </h4> -->
      </div>
      <div class="footer">
        <span class="my-1" class="off_white">
          Powered by
        </span>
        <div>
          <img src="../../../assets//logo/BLUE-LOGO.png" alt="logo">
        </div>
        <span class="off_white mt-1">
          Copyright © 2021-{{year}} GoodSpace Schools Limited. <br>All rights reserved.
        </span>
      </div>
    </div>
    <div class="gss_entry">
      <app-loader></app-loader>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<ng-container *ngIf="isMobile">
  <div class="app-footer flex-column">
    <div>
      <img src="../../../assets/logo/WHITE-LOGO.png" alt="">
    </div>
    <span class="m-auto">
      Copyright © 2021-{{year}} GoodSpace Schools Limited. All rights reserved.
    </span>
  </div>
</ng-container>
