import { Component, OnInit, Input, OnDestroy, OnChanges, HostListener, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ServerHost } from 'src/app/common/global_services/server_url';
import { SwService } from 'src/app/views/sw/services/sw.service';
import { QuestionService } from '../dynamic-form/services/question.service';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-screening-navbar',
  templateUrl: './screening-navbar.component.html',
  styleUrls: ['./screening-navbar.component.scss']
})
export class ScreeningNavbarComponent implements AfterViewInit, OnDestroy, OnInit {

  @Input() logoName = 'blue-logo.png';
  time = new Date();
  qChangeSub: Subscription;
  uid: string;
  innerWidth: number;
  lastNavBarHeight = 20;
  orgDetailsSub: Subscription;
  orgDetails: any;
  hostedAssets = ServerHost.HostedAssets; 
  screeningName: any;
  isMobile: boolean;
  updateStudentIdSub: Subscription;
  constructor(public qcs: QuestionService, public swService: SwService, public breakpointObserver: BreakpointObserver) {
    this.qChangeSub = this.qcs.onQuestionChange$.subscribe(d => this.time = new Date());
    this.orgDetailsSub = this.swService.orgDetailsEvent$.subscribe(d => this.bindData(d));
    this.uid = sessionStorage.getItem('fieldValue');
    this.updateStudentIdSub = this.swService.updateStudentId$.subscribe(d => this.uid = sessionStorage.getItem('fieldValue'));
    this.innerWidth = window.innerWidth;
    
  }

  ngOnInit() :void {
    this.breakpointObserver.observe('(max-width: 611px), (min-width: 375px)')
      .subscribe((d: any) => {
        console.log(d);
        if (d.breakpoints['(max-width: 611px)']) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });

    if (!this.orgDetails && !this.orgDetails?.length && sessionStorage.getItem('orgDetails')) {
      this.orgDetails = JSON.parse(sessionStorage.getItem('orgDetails'));
    }
    if (!this.screeningName && sessionStorage.getItem('screeningName')) {
      this.screeningName = sessionStorage.getItem('screeningName');
    }
  }

  ngAfterViewInit() {
    this.calculaeNavHeight();
  }

  imgLoaded(event) {
    setTimeout(() => this.calculaeNavHeight(), 0);
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    console.log(event, window.innerWidth, window.innerHeight);
    this.innerWidth = window.innerWidth;
    this.calculaeNavHeight();
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    this.innerWidth = window.innerWidth;
    setTimeout(() => this.calculaeNavHeight(), 200);
  }

  bindData(d?) {
    if (d.assessmentName) {
      this.screeningName = d.assessmentName;
    } else {
      this.orgDetails = d;
    }
    this.uid = sessionStorage.getItem('fieldValue');
  }

   // changes padding of body element responsive to navbar.
   calculaeNavHeight() {
    const navHeight = document.getElementById('navBar').clientHeight;
    if (navHeight !== this.lastNavBarHeight) {
      document.getElementsByTagName('body')[0].style.paddingTop = navHeight + 'px';
      this.lastNavBarHeight = navHeight;
    }
}

  ngOnDestroy() {
    this.qChangeSub.unsubscribe();
    this.orgDetailsSub.unsubscribe();
    this.updateStudentIdSub.unsubscribe();
    document.getElementsByTagName('body')[0].style.paddingTop = '0';
  }

}
