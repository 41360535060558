import { BreakpointObserver } from '@angular/cdk/layout';
import { Location } from '@angular/common'
import { Component, OnInit, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SwService } from 'src/app/views/sw/services/sw.service';


@Component({
  selector: 'app-sw-container',
  templateUrl: './sw-container.component.html',
  styleUrls: ['./sw-container.component.scss']
})
export class SwContainerComponent implements OnInit {
  isIpad: Observable<boolean>;
  isWelcomePage: boolean;
  showHeaderAndFooter: Observable<boolean>;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    public location: Location,
    public swService: SwService
  ) { }

  ngOnInit() {
    this.showHeaderAndFooter = this.swService.toggleShowNavAndFooterEvent$;

    if (this.router.url.indexOf('/sw/welcome') >= 0) {
      this.isWelcomePage = true;
    } else {
      this.isWelcomePage = false;
    }

    this.router.events
      .subscribe(r => {
        console.log(r);
        if (this.location.path().indexOf('/sw/welcome') >= 0 ) {
          this.isWelcomePage = true;
        } else {
          this.isWelcomePage = false;
        }
      });

  }
}
