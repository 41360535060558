import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { SpeedDialFabComponent } from '.';
import { MaterialModule } from 'src/app/material';

@NgModule({
    declarations: [SpeedDialFabComponent],
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule
    ],
    exports: [SpeedDialFabComponent]
})
export class SpeedDialFabModule { }
