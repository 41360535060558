import { Injectable } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpErrorResponse,
  HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse
} from '@angular/common/http';
import { LoaderService } from '../loader/loader.service';
import { tap, catchError, finalize } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { Alert } from './alert.service';
import { DatePipe } from '@angular/common';
import { AppStateService } from './app.state.service';

@Injectable({
  providedIn: "root",
})
export class DatafactoryService implements HttpInterceptor {
  url: any;
  token: any;
  public totalRequests = 0;
  isOffline: boolean;

  constructor(
    private datePipe: DatePipe,
    public _httpClient: HttpClient,
    public loaderService: LoaderService,
    public alertService: Alert,
    public router: Router,
    private appStateService: AppStateService
  ) {
    this.appStateService.getSessionStorageFromCookie();
  }

  public getLoggedInUserIpAddress() {
    return this._httpClient.get("https://api.ipify.org?format=json");
  }

  public downloadFile(httpResponse: HttpResponse<ArrayBuffer>) {
    const blob = new Blob([httpResponse.body]);
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    const downloadName = httpResponse.headers.get("download-file-name");
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      downloadName ? downloadName.replace("date_time", this.datePipe.transform(new Date(), 'dd_MM_yyyy_hh_mm_a')) : "Goodspace-excel-download.xlsx"
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    this.alertService.prompt("info", ``, 'Ok', `<b>Report exported to password protected Excel file. <br/> <br/> Excel File password is ${sessionStorage.getItem("ECP")} <br/> <br/> Password can be accessed in your user profile.</b>`);

  }

  public ProcessPostRequest(url: string, params?: any) {
    this.token = localStorage.getItem("GSTK");

    const headers = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/json;charset=utf-8")
      .set("Access-Control-Allow-Origin", "*")
      .set("Authorization", "Bearer " + this.token);
    const returnValue = this.HttpClientPost(url, params, { headers: headers });
    return returnValue;
  }

  private HttpClientPost(url: string, params: any, options: any) {
    // return this._httpClient.get(url,{headers:new HttpHeaders({'Authorization':'Bearer'+this.token})});
    // return this._httpClient.post(url, params, options);
    return new Promise((resolve, reject) => {
      this._httpClient.post(url, params, options).subscribe(
        (data) => {
          resolve(data);
        },
        (errorResult) => {
          if (errorResult.status === 400) {
            this.alertService
              .prompt("alert", errorResult.error, "Ok")
              .then((res) => {
                resolve(res);
              });
          } else {
            reject(errorResult);
          }
        }
      );
    });
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.loaderService.show();
    this.totalRequests++;
    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests < 0) {
          this.totalRequests = 0;
        }
        if (this.totalRequests === 0) {
          this.loaderService.hide();
        }
      })
    );
  }

  public requestBlob(url: string, params) {
    this.token = sessionStorage.getItem("GSTK");
    const headers = new HttpHeaders()
      .set("Access-Control-Allow-Origin", "*")
      .set("Authorization", "Bearer " + this.token);
    return this._httpClient.post(url, params, {
      headers: headers,
      responseType: "arraybuffer",
      observe: "response",
    }).pipe(
      catchError((this.catchHttpError.bind(this)))
    )
  }

  postMethodWithCustomToken(url, params, token) {

    const headers = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/json;charset=utf-8")
      .set("Access-Control-Allow-Origin", "*")
      .set("Authorization", "Bearer " + token);
    return this._httpClient
      .post(url, params, { headers: headers })
      .pipe(
        tap((data: any) => {
          if (
            data[0].responseCode === 3 &&
            (data[0].response === "Failure" || data[0].response === "failure")
          ) {
            this.alertService.prompt("warning", data[0].message, "ok");
          }
          return data;
        }),
        catchError((this.catchHttpError.bind(this)))
      );
  }

  postMethod(url: string, params?, options?) {

    this.token = sessionStorage.getItem("GSTK");
    const headers = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/json;charset=utf-8")
      .set("Access-Control-Allow-Origin", "*")
      .set("Authorization", "Bearer " + this.token);
    return this._httpClient
      .post(url, params, { headers: headers, ...options })
      .pipe(
        tap((data: any) => {
          if (
            !!data?.length &&
            data[0].responseCode === 3 &&
            (data[0].response === "Failure" || data[0].response === "failure")
          ) {
            this.alertService.prompt("warning", data[0].message, "ok");
          }
          return data;
        }),
        catchError(this.catchHttpError.bind(this))
        );
  }

  catchHttpError(error: HttpErrorResponse) {
      const time = this.datePipe.transform(new Date(), "dd-MMM-yy h:mm a");
      this.totalRequests = 0;
      this.loaderService.hide();
      const errorResponse = Array.isArray(error.error) ? error.error[0] : error.error;
      if (this.appStateService.isOffline || error.status === 0) {
        this.alertService.prompt("error",
        "<h6>Sorry! No Internet connection<br>Please try again.<br> If issue still persist please contact your ISP.<br><br>" +
          time +
          "<br><br>Error code: " +
          "net::ERR_INTERNET_DISCONNECTED" +
          "</h6>",
        "OK");
        return;
      }
      if (error.status === 401) {
        sessionStorage.removeItem("orgGuid");
        sessionStorage.removeItem("GSTK");
        this.router.navigateByUrl("/auth/login");
        return;
      } else if (
        error.status === 400 &&
        errorResponse.responseCode === 3 &&
        errorResponse.response === "failure"
      ) {
        this.alertService.prompt("warning", errorResponse.message, "ok");
      } else if (error.status === 400 &&
        error.headers.get("message")
        ) {
        this.alertService.prompt("warning", error.headers.get("message"), "ok");
      }
      else if (error.status === 429) {
        this.alertService.prompt(
          "warning",
          "<h6>Sorry! Too many requests, please try again<br><br>" +
            time +
            "<br><br>Error code: " +
            error.status +
            "</h6>",
          "OK"
        );
      } else {
        this.alertService.prompt(
          "warning",
          "<h6>Sorry! An unknown error occurred<br>Please try again.<br> Issue has been noted with our Technical Support team.<br><br>" +
            time +
            "<br><br>Error code: " +
            error.status +
            "</h6>",
          "OK"
        );
      }
      //return throwError("post Catch" + error);
  }
  postAnonymousMethod(url: string, params?, options?) {
    // if (!sessionStorage.getItem('GSTK')) {
    const headers = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/json;charset=utf-8")
      .set("Access-Control-Allow-Origin", "*");
    // .set('Authorization', 'Bearer ' + this.token);
    return this._httpClient
      .post(url, params, { headers: headers, ...options })
      .pipe(
        tap((data: any) => {
          return data;
        }),
        catchError(this.catchHttpError.bind(this))
      );
    // } else { // if theres no local storage
    //   this.totalRequests = 0;
    //   this.loaderService.hide();
    //   // this.alertService.prompt('warning', 'Your session has been expired', 'LOGOUT')
    //   //   .then((res) => {
    //   //   //  window.location.href = ServerHost.MvcAppUrl;
    //   //   });
    // }
  }

  // Get Method
  GetMethod(url: string) {
    return this._httpClient.get(url);
  }
}
