import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class Alert {

  constructor() { }
  showToast(type, title: string, timer = 3000, customClass?) {
    return Swal.fire({
      type: typeof (type) === 'number' ? this.getRespectiveType(type) : type,
      title: title,
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: timer,
      customClass: customClass
    });
  }

  prompt(type, title: string, ok, text?) {  // this will going to prompt a message with only one button and spacify the type as well
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        title: 'text-break font_size_h4',
        confirmButton: 'btn btn-primary btn-lg',
      },
      buttonsStyling: false,
    });
    return swalWithBootstrapButtons.fire({
      allowOutsideClick: false,
      type: typeof (type) === 'number' ? this.getRespectiveType(type) : type,
      title: title,
      html: text ? text : '',
      confirmButtonText: ok,
      confirmButtonColor: '#4dbd74'
    });
  }

  confirm(type?, title?: string, text?, cancel?, ok?) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary ml-3',
        cancelButton: 'btn btn-outline-primary',
        title: 'text-break font_size_h4'
      },
      buttonsStyling: false,
    });
    return swalWithBootstrapButtons.fire({
      html: text,
      allowOutsideClick: false,
      type: typeof (type) === 'number' ? this.getRespectiveType(type) : type,
      title: title,
      showCancelButton: true,
      cancelButtonText: cancel,
      cancelButtonColor: '#f86c6b',
      confirmButtonText: ok,
      confirmButtonColor: '#4dbd74',
      reverseButtons: true,
    });
  }
  // "warning"
  // "error"
  // "success"
  // "info"
  getRespectiveType(responseCode) {
    let type = '';
    switch (responseCode) {
      case (2 || 4):
        type = 'success';
        break;
      case (0 || 3):
        type = 'error';
        break;
      case 5:
        type = 'warning';
        break;
      case 6:
        type = 'info';
        break;
      default:
        type = 'success';
    }
    return type;
  }
}
