<div class="d-flex justify-content-between w-100">
  <div class="w-100 d-flex">
    <ng-template ngFor let-breadcrumb [ngForOf]="breadcrumbs" let-last=last>
      <li class="breadcrumb-item"
        *ngIf="breadcrumb.label.title && breadcrumb.url.substring(breadcrumb.url.length-1) == '/'|| breadcrumb.label.title && last"
        [ngClass]="{active: last}">
        <a *ngIf="!last"
          [routerLink]="breadcrumb.label.url ? breadcrumb.label.url : breadcrumb.url">{{breadcrumb.label.title}}</a>
        <span *ngIf="last">{{breadcrumb.label.title}}</span>
      </li>
    </ng-template>
  </div>
</div>
