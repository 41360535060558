<nav class="sidebar-nav">
  <ul class="nav">
    <div *ngFor="let navitem of navigation" (click)="onClick(navitem.NavigationId)">
      <li *ngIf="isDivider(navitem)" class="nav-divider"></li>
      <ng-template [ngIf]="isTitle(navitem)">
        <app-sidebar-nav-title [title]='navitem'></app-sidebar-nav-title>
      </ng-template>
      <ng-template [ngIf]="!isDivider(navitem)&&!isTitle(navitem)">
        <app-sidebar-nav-item [item]='navitem'></app-sidebar-nav-item>
      </ng-template>
    </div>
  </ul>
  <span class="my-2 ml-2 mb-2 text-center; "><small>ver: {{Version}}</small></span>
</nav>
