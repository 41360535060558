import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { regexp } from 'src/app/common/global_services/regexp.service';
import { QuestionBase } from '../question.model';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {
  nextSection = new Subject();
  changeSection$ = this.nextSection.asObservable();
  nextQuestion = new Subject();
  onQuestionChange$ = this.nextQuestion.asObservable();
  constructor() { 
  }

  getRegExp(q) {
    if (q.key === "Email") {
      return new RegExp(regexp.Email);
    } else if (q.key === 'MobileNumber') {
      return new RegExp(regexp.NZMobileNumPattern);
    }
    return new RegExp(q.validators.regEx);
  }

  addValidators(question) {
    console.log(question);
    const validators = question.required ? [Validators.required] : [];
    if (question.validators) {
      if (question.validators.regEx) {
        validators.push(Validators.pattern(this.getRegExp(question)));
      }
    }
    return validators;
  }

  toFormGroup(questions: QuestionBase<any>[]) {
    const group: any = {};

    questions.forEach(question => {
      group[question.key] = new UntypedFormControl(question.value || '', this.addValidators(question));
      if (question.prefixComponent) {
        group[question.prefixComponent.key] = new UntypedFormControl(question.prefixComponent.value || '', this.addValidators(question.prefixComponent))
      }
    });
    return new UntypedFormGroup(group);
  }
  createNewQuestionBase(q, IsSkipped): QuestionBase<any> {
      return new QuestionBase({
        value: q.value ? q.value : '',
        key: q.QuestionReference,
        label: q.QuestionText,
        required: q.IsMandatory,
        order: q.QuestionId,
        controlType: q.ControlType,
        type: q.FieldType,
        options: q.ResponseOptionJson ? q.ResponseOptionJson : [],
        disable: q.IsReadOnly,
        isHavingProbingQuestion: q.IsHavingProbingQuestion,
        questionId: q.QuestionId,
        isProbing: q.IsProbing,
        isSkipped: q.IsSkipped,
        isAnswered: q.IsAnswered,
      })
  }
}
