import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  Router, NavigationEnd, NavigationStart, NavigationCancel, NavigationError,
  RouteConfigLoadStart, RouteConfigLoadEnd, GuardsCheckEnd
} from '@angular/router';
import { Location } from '@angular/common';
import { LoaderService } from './common/loader/loader.service';
import { SwService } from './views/sw/services/sw.service';
import { AppHostSettings, Env } from './common/global_services/server_url';
import { Title } from '@angular/platform-browser';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Alert } from './common/global_services/alert.service';
import { ConnectionService } from 'ng-connection-service';
import { AppStateService } from './common/global_services/app.state.service';

export let browserRefresh = false;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body',
  template: `
  <router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit, AfterViewInit {
  org_metadata: any;
  idleCount = 0;
  constructor(
    private router: Router,
    public location: Location,
    public loaderService: LoaderService,
    public swService: SwService,
    public title: Title,
    private idle: Idle,
    public alert: Alert,
    private appStateService: AppStateService
  ) {
    this.appStateService.watchInternetConnection();
  }

  reset() {
    this.idle.watch();
  }

  ngOnInit() {
    this.idle.setIdle(300);
    this.idle.setTimeout(300);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleStart.subscribe(() => {
      this.alert.prompt('info', 'For your security your session is about to time out. If you wish to stay logged in click the button below <br><br>ref: 301', 'Stay logged in')
        .then(r => {
          this.idle.watch();
        });
    });
    this.idle.onTimeout.subscribe(() => {
      this.forceLogout();
    });
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        if (this.location.path() === '/auth/login' || this.location.path() === '/auth/forgot-password'
          || this.location.path() === '/sw/welcome' || this.location.path() === '/auth/register' || this.location.path() === "/pageNotFound") {
          this.idle.stop();
        } else {
          this.reset();
        }
        this.loaderService.hide();
        this.appStateService.bindThemeColor();
      }
      if (e instanceof NavigationCancel) {
        this.loaderService.hide();
      }
      if (e instanceof NavigationError) {
        this.loaderService.hide();
      }
      if (e instanceof RouteConfigLoadEnd) {
        this.loaderService.hide();
      }
      if (e instanceof GuardsCheckEnd) {
        this.loaderService.hide();
      }
      if (e instanceof NavigationStart) {
        browserRefresh = !this.router.navigated;
        this.appStateService.bindThemeColor();
        this.loaderService.show();
      }
      if (e instanceof RouteConfigLoadStart) {
        this.loaderService.show();
      }
    });
  }

  ngAfterViewInit() {
    this.appStateService.bindThemeColor();
  }

  forceLogout() {
    const reDirctedPath = this.location.path().includes('/sw/');
    this.swService.clearSessionStorageExcept(['orgGuid', 'orgDetails', 'brandcolor']);
    window.location.replace(reDirctedPath ? window.location.origin + '/#/sw/welcome' : window.location.origin + '/#/auth/login');
    // this.router.navigateByUrl(reDirctedPath ? '/sw/welcome' : '/auth/login', {replaceUrl: true})
    // .then(res => {
    this.alert.prompt('info', 'For your security your session has timed out, To access GoodSpace Portal you will need to log back in <br><br>ref: 302', 'Login again');
    // })
    // .catch(err => console.log("AppComponent::error in navigating at force logout..", err));
  }

}
