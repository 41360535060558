import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { LoaderState } from './loader.model';

@Injectable({
  providedIn: 'root'
})
export class LoaderService implements OnDestroy {

  private loaderSubject = new Subject<LoaderState>();
  loaderState = this.loaderSubject.asObservable();
  loading: boolean = true;
  subscribtion: Subscription;

  constructor() {
    this.subscribtion = this.loaderSubject.subscribe((state: LoaderState) => this.loading = state.show);
  }

  show() {
    this.loaderSubject.next(<LoaderState>{ show: true });
  }

  hide() {
    this.loaderSubject.next(<LoaderState>{ show: false });
  }

  getState() {
    return this.loaderState;
  }

  ngOnDestroy() {
    this.subscribtion.unsubscribe();
  }

}
