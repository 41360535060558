import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DatafactoryService } from 'src/app/common/global_services/datafactory.service';
import { RequestParams } from 'src/app/common/global_services/request-params';
import { ServerHost } from 'src/app/common/global_services/server_url';

export let ScreeningWizard = { 
  CheckScreeningIdAndGetMetaData: ServerHost.WebApi + 'UserScreeningWizard/CheckScreeningIdAndGetMetaData ',
  CheckScreeningIdAndGivenFieldValue: ServerHost.WebApi + 'UserScreeningWizard/CheckScreeningIdAndGivenFieldValue ',
  CheckScreeningIdByUserEmail: ServerHost.WebApi + 'UserScreeningWizard/CheckScreeningIdByUserEmail ',
  CheckScreeningIdAndResetEmail: ServerHost.WebApi + 'UserScreeningWizard/CheckScreeningIdAndResetEmail ',
  SaveUserProfileData: ServerHost.WebApi + 'UserScreeningWizard/SaveUserProfileData',
  GetQuestionsByScreeningId: ServerHost.WebApi + 'UserScreeningWizard/GetQuestionsByScreeningId',
  GetSectionHelpByScreeningId: ServerHost.WebApi + 'UserScreeningWizard/GetSectionHelpByScreeningId',
  SavePartialUserScreening: ServerHost.WebApi + 'UserScreeningWizard/SavePartialUserScreening',
  SaveFinalUserScreening: ServerHost.WebApi + 'UserScreeningWizard/SaveFinalUserScreening',
  ValidateResumeCode: ServerHost.WebApi + 'UserScreeningWizard/ValidateResumeCode',
  GetOrgDetails: ServerHost.WebApi + 'UserScreeningWizard/GetOrgDetails'
};

@Injectable({
  providedIn: 'root'
})
export class SwService {

  orgDetailsEvent = new Subject();
  orgDetailsEvent$ = this.orgDetailsEvent.asObservable();
  toggleShowNavAndFooterEvent = new Subject();
  toggleShowNavAndFooterEvent$ = <Observable<boolean>>this.toggleShowNavAndFooterEvent.asObservable();
  
  isQuestionsFormValid = new Subject();
  isQuestionsFormValid$ = <Observable<boolean>>this.isQuestionsFormValid.asObservable();

  updateStudentId = new Subject();
  updateStudentId$ = <Observable<boolean>>this.updateStudentId.asObservable();

  constructor(public dataFactory: DatafactoryService,
    public _requestparams: RequestParams) { }
    
  CheckScreeningIdAndGetMetaData(params?) {
    return this.dataFactory.postAnonymousMethod(ScreeningWizard.CheckScreeningIdAndGetMetaData, this._requestparams.ConstructRequest(params));
  }
  CheckScreeningIdAndGivenFieldValue(params?) {
    return this.dataFactory.postAnonymousMethod(ScreeningWizard.CheckScreeningIdAndGivenFieldValue, this._requestparams.ConstructRequest(params));
  }
  CheckScreeningIdByUserEmail(params?) {
    return this.dataFactory.postAnonymousMethod(ScreeningWizard.CheckScreeningIdByUserEmail, this._requestparams.ConstructRequest(params));
  }
  CheckScreeningIdAndResetEmail(params?) {
    return this.dataFactory.postAnonymousMethod(ScreeningWizard.CheckScreeningIdAndResetEmail, this._requestparams.ConstructRequest(params));
  }
  SaveUserProfileData(params?) {
    return this.dataFactory.postAnonymousMethod(ScreeningWizard.SaveUserProfileData, this._requestparams.ConstructRequest(params));
  }
  GetQuestionsByScreeningId(params?) {
    return this.dataFactory.postAnonymousMethod(ScreeningWizard.GetQuestionsByScreeningId, this._requestparams.ConstructRequest(params));
  }
  GetSectionHelpByScreeningId(params?) {
    return this.dataFactory.postAnonymousMethod(ScreeningWizard.GetSectionHelpByScreeningId, this._requestparams.ConstructRequest(params));
  }
  SavePartialUserScreening(params?) {
    return this.dataFactory.postAnonymousMethod(ScreeningWizard.SavePartialUserScreening, this._requestparams.ConstructRequest(params));
  }
  SaveFinalUserScreening(params?) {
    return this.dataFactory.postAnonymousMethod(ScreeningWizard.SaveFinalUserScreening, this._requestparams.ConstructRequest(params));
  }
  ValidateResumeCode(params?) {
    return this.dataFactory.postAnonymousMethod(ScreeningWizard.ValidateResumeCode, this._requestparams.ConstructRequest(params));
  }
  GetOrgDetails(params?) {
    return this.dataFactory.postAnonymousMethod(ScreeningWizard.GetOrgDetails, this._requestparams.ConstructRequest(params));
  }
  deferResponse(response) {
    return new Promise((resolve, reject) => resolve(response));
  }
  rejectablePromise(response) {
    return new Promise((resolve, reject) => reject(response));
  }
  clearSessionStorageExcept(exceptionsArr) {
    Object.keys(sessionStorage).forEach(key => {
      const index = exceptionsArr.findIndex(exceptionStr => exceptionStr === key);
      if (index >= 0) {
        return;
      } else {
        sessionStorage.removeItem(key);
      }
    });
  }
}
